<template>
    <div>
        <div style="position: relative">
            <v-btn-toggle
                mandatory
                tile
                color="tertiary"
                group>
                <v-btn class="mr-2" @click="showSampleSets = true">
                    Sample Sets
                </v-btn>
                <v-btn @click="showSampleSets = false">
                    Member Samples
                </v-btn>
            </v-btn-toggle>
        </div>
        <div
            v-if="showSampleSets"
            class="lab-test-sample-sets">
            <lab-sample-sets :filter="filter" />
        </div>
        <configurable-table-view v-else page-title="Samples">
            <template v-slot:table="{ tableSchema }">
                <samples-table
                    :table-schema="tableSchema"
                    :filter="sampleFilter" />
            </template>
        </configurable-table-view>
    </div>
</template>

<script>
export default {
    components: {
        labSampleSets: () => import("@/views/labTestDashboard/LabSampleSets"),
        configurableTableView: () => import("@/components/ConfigurableTableView"),
        samplesTable: () => import("@/tables/SamplesTable")
    },
    props: {
        filter: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            showSampleSets: true,
            sampleFilter: {
                sampleSetId: null
            }
        };
    },
}
</script>
<style scoped lang="scss">
    .lab-test-sample-sets {
        height: calc(100% - 50px);
        overflow-y: hidden;
        overflow: auto;
        position: relative
    }
</style>
